import React, {useState, useEffect,useCallback} from "react";
import {Drawer, Typography, makeStyles, FormControl, InputLabel, ButtonGroup, Button, Chip} from '@material-ui/core';
import {Done, Clear, SaveAlt} from '@material-ui/icons';
import InboxIcon from '@material-ui/icons/Inbox';
import { 
	Datagrid, List, ArrayField, TextField, NumberField, FunctionField, BooleanField,
	TextInput, SelectInput,
	Show, SimpleShowLayout,
	useNotify, useDataProvider, useListContext, useTranslate,
	Filter,
	DateTimeInput,
	downloadCSV,
	TopToolbar, CreateButton, SaveButton, Toolbar, SimpleForm, Create, Edit,
	BulkDeleteButton, Button as RaButton, EditButton,
	Loading, Error,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { Route } from 'react-router';
import {DateTimeField} from './extends/fields';
import { useDispatch } from 'react-redux';
import { refreshView } from 'ra-core';
import {fetchApi} from './data-provider';

const path=require('path');

// const QuickFilter = ({ label }) => {
//     return <Chip label={label} />;
// };
const drawerWidth=380;
const useStyles = makeStyles(
    theme => ({
        message: {
            textAlign: 'center',
            opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
            margin: '0 1em',
            color:
                theme.palette.type === 'light'
                    ? 'inherit'
                    : theme.palette.text.primary,
        },
        icon: {
            width: '9em',
            height: '9em',
        },
        toolbar: {
            textAlign: 'center',
            marginTop: '2em',
        },
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
		},
		fixFee :{
			'-webkit-appearance': 'none !important',
			margin: 0
		}
    }),
    { name: 'RaEmpty' }
);

const OptionButtons =({permissions, ...props})=>{
	const {record}=props;
	var dispatch = useDispatch();
	const notify=useNotify();

	function TakeAction(resource, params) {
		return fetchApi(`${resource}/${params.action}`, {
			method:'POST',
			body:JSON.stringify({_id:decodeURIComponent(params.id)})
		}).then(({json})=>{
			notify('done', 'info');
			return {data:json};
		}).catch((e)=>{
			notify(e.message, 'warning');
		})
	}

	return (
		<ButtonGroup variant="text" color="primary" aria-label="text primary button group">
			{record.err? <Button onClick={()=>{TakeAction('bills', {action:'resend', id:record.id})}}>Resend</Button> : null}
			{/* {permissions==='admin'?<Button onClick={()=>{TakeAction('bills', {action:'debugBill', id:record.id}).then(()=>{dispatch(refreshView())})}}>debug</Button>:null}
			{permissions==='admin'?<Button onClick={()=>{TakeAction('bills', {action:'adminUseBill', id:record.id}).then(()=>{dispatch(refreshView())})}}>force</Button>:null} 
			<Button onClick={()=>{TakeAction('bills', {action:'refund', id:record.id}).then(()=>{dispatch(refreshView())})}}>refund</Button>*/}
		</ButtonGroup>
	)
}

const ListActions = ({ basePath }) => (
    <TopToolbar>
        <CreateButton basePath={basePath} />
     </TopToolbar>
);

const SaveWithNoteButton = ({ handleSubmitWithRedirect, acl, ...props }) => {
	const { redirect } = props;
	const form = useForm();
	const handleClick = useCallback(() => {
		var formdata = form.getState().values;

		// form.change('share', Number((1-Number(formdata.share)/100).toFixed(4)))
		// var mdr=parseFloat(formdata.mdr);
		// if (mdr>=1) mdr=Number((mdr/100).toFixed(4));
		// form.change('mdr', mdr)

		for (var key in formdata) {
			if (key=='id') continue;
			form.change(`game.${key}.key`, formdata[key]);
			form.change(key, undefined);
		}
		// if (formdata.paymentMethod) {
		// 	for (var key in formdata.paymentMethod) {
		// 		var payment=formdata.paymentMethod[key];
		// 		var mdr=parseFloat(payment.mdr);
		// 		if (mdr>=1) mdr=Number((mdr/100).toFixed(4));
		// 		form.change(`paymentMethod.${key}.mdr`, mdr)
		// 	}
		// }

		// if (acl) form.change('acl', acl)

		// if (formdata.providers) {
		// 	for (const name in formdata.providers) {
		// 		var prd=formdata.providers[name];
		// 		if (prd.enabled!=null) {
		// 			form.change('providers.'+name+'.disabled', !prd.enabled);
		// 			form.change('providers.'+name+'.enabled', undefined);
		// 		}
		// 	}
		// }

		handleSubmitWithRedirect(redirect);
	}, [form]);

	return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

const PostToolbar = props => {
	return (<Toolbar {...props}>
			<SaveWithNoteButton
				label="创建"
				submitOnEnter={false}
				{...props}
			/>
		</Toolbar>
	);
}

function CreateAndEditView(method, props) {
	const dp=useDataProvider();
	const [agents, setAgents] = useState();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();

	useEffect(()=>{
		dp.getList('users', {filter:{acl:'agent'}})
		.then(({data})=>{
			setAgents(data);
			setLoading(false);
		})
		.catch(e=>{
			setError(e);
			setLoading(false);
		});
	}, [])
	if (loading) return <Loading />
	if (error) return <Error />
	
	if (loading) return <Loading />

	const AgentSelector=(props)=>{
		if (method==='Create') return <SelectInput {...props}/>
		else return null;
	}

	var toolbar=method=='Create'?<PostToolbar/>:<PostToolbar label='Save'/>
	return (<SimpleForm toolbar={toolbar}>
			<AgentSelector source="id" label="代理" choices={[{id:'default', name:'主站'}].concat(agents)} fullWidth={true} initialValue='default'/> 
			{method!='Create'?<input name="id" value={props.id} hidden/>:null}
			<TextInput source="牛牛" label="牛牛收款私钥" fullWidth={true} helperText='钱包的Private Key'/>
			<TextInput source="单双" label="单双收款私钥" fullWidth={true} helperText='钱包的Private Key'/>
			<TextInput source="尾数" label="尾数收款私钥" fullWidth={true} helperText='钱包的Private Key'/>
			<TextInput source="大小" label="大小收款私钥" fullWidth={true} helperText='钱包的Private Key'/>
		</SimpleForm>)
}

export const GameCreator =props => (<Create {...props} >{CreateAndEditView('Create', {...props, redirect:'list'})}</Create>)
export const GameEditor =props => (<Edit {...props} >{CreateAndEditView('Edit', {...props, redirect:'list'})}</Edit>)


const PostBulkActionButtons = props => {
	const notify=useNotify();

	function TakeAction(resource, params) {
		return fetchApi(`${resource}/${params.action}`, {
			method:'POST',
			body:JSON.stringify({_ids:(params.selectedIds)})
		}).then(({json})=>{
			notify('done', 'info');
			return {data:json};
		}).catch((e)=>{
			notify(e.message, 'warning');
		})
	}

    return <React.Fragment>
        <RaButton  label='归集资金' {...props} onClick={()=>TakeAction('games', {action:'collect', ...props})}><SaveAlt /></RaButton>
        {/* default bulk delete action */}
        <BulkDeleteButton {...props} />
    </React.Fragment>
};

const GameField =({record, source, ...props}) => {
	return <ul>
		{((game)=>{
			var eles=[];
			for (var ele in game) {
				eles.push( <li>{
					(({address, trx, usdt})=>{
						// var Addr=<span>{address||''}</span>;
						// var T=<span>{trx?`TRX ${trx/1000000}`:''}</span>;
						// var U=<span>{usdt?`USDT ${usdt/1000000}`:''}</span>;
						return <span>{ele} {address||''} {!!trx?`TRX ${trx/1000000}`:''} {!!usdt?`USDT ${usdt/1000000}`:''}</span>
					})(game[ele])
				}</li>)
			}
			return eles;
		})(record[source])}
		</ul>
}
const Empty = props => {
    const { basePath } = useListContext(props);
    const classes = useStyles(props);
    const translate = useTranslate();

    return (
        <>
            <div className={classes.message}>
                <InboxIcon className={classes.icon} />
                <Typography variant="h4" paragraph>
                    {translate(`游戏尚未配置`)}
                </Typography>
				<Typography variant="body1">
					{translate(``)}
				</Typography>
            </div>
			<div className={classes.toolbar}>
				<CreateButton variant="contained" basePath={basePath} redirect='list'/>
			</div>
        </>
    );
};

export const SettingList = ({permissions, ...props}) => {
	const classes = useStyles(props);
	const handleClose = () => {
        props.history.push('/games');
    }

	return (
	<React.Fragment>
		<List {...props}  title="游戏设置" bulkActionButtons={<PostBulkActionButtons/>} exporter={false} actions={<ListActions />} empty={<Empty />}>
			<Datagrid>
				<TextField source="id" label="代理"/>
				<GameField source='game' />
				<DateTimeField source="createTime" label="时间"/>
				<EditButton />
			</Datagrid>
		</List>
		<Route path="/games/create">
            {({ match }) => (
                <Drawer
                    className={classes.drawer}
                    open={!!match}
                    anchor="right"
                    onClose={handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <GameCreator
                        // className={classes.drawerContent}
                        onCancel={handleClose}
                        {...props}
                    />
                </Drawer>
            )}
        </Route>
		<Route path="/games/:id">
			{({ match , location}) => {
				const isMatch =	match  && match.params && match.params.id !== 'create';
				if (!isMatch) return null;
				if (!match.isExact && path.basename(location.pathname)!=='1') return null;
				return (
					<Drawer
						open={isMatch}
						anchor="right"
						onClose={handleClose}
						className={classes.drawer}
						classes={{
							paper: classes.drawerPaper,
						}}
					>
						{isMatch ? (
							<GameEditor
								// className={classes.drawerContent}
								id={isMatch ? decodeURIComponent(match.params.id) : null}
								onCancel={handleClose}
								{...props}
							/>
						) : 
						null
						}
					</Drawer>
				);
			}}
		</Route>
	</React.Fragment>
	);
}

export default {
	list:SettingList,
//	show:SettingShow,
}