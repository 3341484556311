import React, {useState, useEffect} from "react";
import {Drawer, Typography, makeStyles, FormControl, InputLabel, ButtonGroup, Button, Chip} from '@material-ui/core';
import {Done, Clear} from '@material-ui/icons';
import InboxIcon from '@material-ui/icons/Inbox';

import { 
	Datagrid, List, TextField, NumberField, FunctionField, BooleanField,
	TextInput, SelectInput,
	Show, SimpleShowLayout,
	useNotify, useDataProvider, useListContext, useTranslate,
	Filter,
	DateTimeInput,
	downloadCSV,
	TopToolbar, CreateButton, SaveButton, Toolbar, SimpleForm, Create
} from 'react-admin';
import { Route } from 'react-router';
import {DateTimeField} from './extends/fields';
import { useDispatch } from 'react-redux';
import { refreshView } from 'ra-core';
import {fetchApi} from './data-provider';
import {ExtendedDatagrid, StatusFiled} from './extends';
import get from 'lodash/get';
import jsonExport from 'jsonexport/dist';

const drawerWidth=380;
const useStyles = makeStyles(
    theme => ({
        message: {
            textAlign: 'center',
            opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
            margin: '0 1em',
            color:
                theme.palette.type === 'light'
                    ? 'inherit'
                    : theme.palette.text.primary,
        },
        icon: {
            width: '9em',
            height: '9em',
        },
        toolbar: {
            textAlign: 'center',
            marginTop: '2em',
        },
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
		},
		fixFee :{
			'-webkit-appearance': 'none !important',
			margin: 0
		}
    }),
    { name: 'RaEmpty' }
);

// const QuickFilter = ({ label }) => {
//     return <Chip label={label} />;
// };

const BillFilter =props=>{
	const dp=useDataProvider(), notify=useNotify();
	var [providers, setProviders]=useState();
	useEffect(()=>{
		dp.getList('providers')
		.then(({data})=>setProviders(data))
		.catch(e=>notify(e.message, 'warning'))
	}, []);
	return (
	<Filter {...props}>
		<TextInput label="订单id" source="id"/>
		<TextInput label="商户订单" source="merchantOrderId" alwaysOn/>
		<TextInput label="供应商订单" source="providerOrderId"/>
		<TextInput label="商户" source="merchantName" alwaysOn/>
		<DateTimeInput label="开始日期" source="startTime"/>
		<DateTimeInput label="结束日期" source="endTime"/>
		<SelectInput label="订单状态" source="used" choices={[
			{id:true, name:'已完成'},
			{id:{$ne:true}, name:'未完成'},
			{id:undefined, name:'全部'},
		]} />
		<SelectInput label="供应商" source="provider" choices={providers}/>
	</Filter>)
}

const OptionButtons =({permissions, ...props})=>{
	const {record}=props;
	var dispatch = useDispatch();
	const notify=useNotify();

	function TakeAction(resource, params) {
		return fetchApi(`${resource}/${params.action}`, {
			method:'POST',
			body:JSON.stringify({_id:decodeURIComponent(params.id)})
		}).then(({json})=>{
			notify('done', 'info');
			return {data:json};
		}).catch((e)=>{
			notify(e.message, 'warning');
		})
	}

	return (
		<ButtonGroup variant="text" color="primary" aria-label="text primary button group">
			{record.activated?null:<Button onClick={()=>{TakeAction('senderAddresses', {action:'active', id:record.id}).then(()=>dispatch(refreshView()))}}>Active</Button>}
			{/* {permissions==='admin'?<Button onClick={()=>{TakeAction('bills', {action:'debugBill', id:record.id}).then(()=>{dispatch(refreshView())})}}>debug</Button>:null}
			{permissions==='admin'?<Button onClick={()=>{TakeAction('bills', {action:'adminUseBill', id:record.id}).then(()=>{dispatch(refreshView())})}}>force</Button>:null} 
			<Button onClick={()=>{TakeAction('bills', {action:'refund', id:record.id}).then(()=>{dispatch(refreshView())})}}>refund</Button>*/}
		</ButtonGroup>
	)
}
const showShare=(record)=>{
	var paymentMethod=record.paymentMethod=='QRIS'?'eWallet':record.paymentMethod
	var p=get(record, ['payment', paymentMethod], {});
	var ret='';
	p.mdr && (ret+=(p.mdr*100).toFixed(2)+'%');
	p.fix_fee && (ret+=(ret.length?'+':'')+p.fix_fee);
	return ret
}

const ListActions = ({ basePath }) => (
    <TopToolbar>
        <CreateButton basePath={basePath} />
     </TopToolbar>
);

const PostToolbar = props => {
	return (<Toolbar {...props}>
			<SaveButton
				label="创建"
				submitOnEnter={false}
				{...props}
			/>
		</Toolbar>
	);
}

function CreateView(method, props) {

	return (<SimpleForm toolbar={<PostToolbar/>}>
			<TextInput source="key" label="私钥" fullWidth={true}  helperText='钱包的Private Key'/>
		</SimpleForm>)
}

export const AddrCreator =props => (<Create {...props} >{CreateView('Create', {...props, redirect:'list'})}</Create>)

// const exporter = posts => {
//     const postsForExport = posts.map(post => {
//         const { id, merchantName, merchantOrderId, providerOrderId, provider, paymentMethod, money, paidmoney, currency,time, status, recon_id } = post; // omit backlinks and author
//         return { id, merchantName, merchantOrderId, providerOrderId, provider, paymentMethod, money, paidmoney, '费用':showShare(post), currency,time, status, Settled:!!recon_id};
//     });
//     jsonExport(postsForExport, {
//         headers: ['id', 'merchantName', 'merchantOrderId', 'providerOrderId', 'provider', 'paymentMethod', 'money', 'paidmoney', '费用', 'currency','time', 'status', 'Settled'], // order fields in the export
// 		rename:['id', '商户', '商户订单','供应商订单', '供应商', '支付方式', 'money', '实际支付', '费用', 'currency', 'time', 'status', 'Settled']
//     }, (err, csv) => {
//         downloadCSV(csv, 'trans-'+datestring()); // download as 'posts.csv` file
//     });
// };

const Empty = props => {
    const { basePath } = useListContext(props);
    const classes = useStyles(props);
    const translate = useTranslate();

    return (
        <>
            <div className={classes.message}>
                <InboxIcon className={classes.icon} />
                <Typography variant="h4" paragraph>
                    {translate(`回款地址尚未配置`)}
                </Typography>
				<Typography variant="body1">
					{translate(``)}
				</Typography>
            </div>
			<div className={classes.toolbar}>
				<CreateButton variant="contained" basePath={basePath} />
			</div>
        </>
    );
};

export const AddressesList = ({permissions, ...props}) => {
    const classes = useStyles(props);
	const handleClose = () => {
        props.history.push('/senderAddresses');
    }

	return (
        <React.Fragment>
		<List {...props}  title="回款地址" bulkActionButtons={true} exporter={false} actions={<ListActions />} empty={<Empty />}>
			<Datagrid>
				<TextField source="address" label="地址"/>
				<FunctionField source="TRX"  label="TRX" render={rec=>(rec.trx||0)/1000000}/>
                <FunctionField source="USDT" label='USDT' render={rec=>(rec.usdt||0)/1000000} />
                <BooleanField source='activated' label="默认" />
				<OptionButtons permissions={permissions} alwaysOn={true}/>
			</Datagrid>
		</List>
        <Route path="/senderAddresses/create">
            {({ match }) => (
                <Drawer
                    className={classes.drawer}
                    open={!!match}
                    anchor="right"
                    onClose={handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <AddrCreator
                        // className={classes.drawerContent}
                        onCancel={handleClose}
                        {...props}
                    />
                </Drawer>
            )}
        </Route>
        </React.Fragment>
	);
}

export default {
	list:AddressesList,
//	show:SettingShow,
}