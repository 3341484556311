import React, {useState, useEffect} from "react";
import {ButtonGroup, Button, Chip} from '@material-ui/core';
import {Done, Clear, Send} from '@material-ui/icons';
import { 
	List, TextField, NumberField, FunctionField, BooleanField,
	TextInput, SelectInput, BooleanInput,
	Show, SimpleShowLayout,
	useNotify, useDataProvider, 
	Filter,
	DateTimeInput,
	downloadCSV,
	Button as RaButton,
} from 'react-admin';
import {DateTimeField} from './extends/fields';
import { useDispatch } from 'react-redux';
import { refreshView } from 'ra-core';
import {fetchApi} from './data-provider';
import {ExtendedDatagrid, StatusFiled} from './extends';
import get from 'lodash/get';
import jsonExport from 'jsonexport/dist';

const pad = function(n, size) {
	var s = String(n);
	while (s.length < (size || 2)) {s = "0" + s;}
	return s;
}

const datestring=(t) =>{
	if (!t) t=new Date();
	else if (!(t instanceof Date)) t=new Date(t);
	return `${pad(t.getFullYear(), 4)}${pad(t.getMonth()+1)}${pad(t.getDate())}`;
}

export const BillShow =props=> (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="txID"/>
			<TextField source="blockID" />
			<TextField source="owner_address" />
			<TextField source="game" label="游戏" />
			<TextField source="amount" label="投注"/>
			<TextField source="result" label="输赢"/>
		</SimpleShowLayout>
	</Show>
)

// const QuickFilter = ({ label }) => {
//     return <Chip label={label} />;
// };

const BillFilter =props=>{
	// const dp=useDataProvider(), notify=useNotify();
	// var [providers, setProviders]=useState();
	// useEffect(()=>{
	// 	dp.getList('providers')
	// 	.then(({data})=>setProviders(data))
	// 	.catch(e=>notify(e.message, 'warning'))
	// }, []);
	return (
	<Filter {...props}>
		<TextInput label="blockID" source="blockID"/>
		<TextInput label="txID" source="txID" alwaysOn/>
		<TextInput label="玩家地址" source="owner_address"/>
		<TextInput label="代理" source="vendor"/>
		<DateTimeInput label="开始日期" source="startTime"/>
		<DateTimeInput label="结束日期" source="endTime"/>
		<BooleanInput label="包含机器人" source="deamon"/>
	</Filter>)
}

const OptionButtons =({permissions, ...props})=>{
	const {record}=props;
	var dispatch = useDispatch();
	const notify=useNotify();

	function TakeAction(resource, params) {
		return fetchApi(`${resource}/${params.action}`, {
			method:'POST',
			body:JSON.stringify({_id:decodeURIComponent(params.id)})
		}).then(({json})=>{
			notify('done', 'info');
			return {data:json};
		}).catch((e)=>{
			notify(e.message, 'warning');
		})
	}

	return (
		<ButtonGroup variant="text" color="primary" aria-label="text primary button group">
			{(record.return_back && (record.err||record.status=='failed'))? <Button onClick={()=>{TakeAction('bills', {action:'resend', id:record.id})}}>Resend</Button> : null}
			{/* {permissions==='admin'?<Button onClick={()=>{TakeAction('bills', {action:'debugBill', id:record.id}).then(()=>{dispatch(refreshView())})}}>debug</Button>:null}
			{permissions==='admin'?<Button onClick={()=>{TakeAction('bills', {action:'adminUseBill', id:record.id}).then(()=>{dispatch(refreshView())})}}>force</Button>:null} 
			<Button onClick={()=>{TakeAction('bills', {action:'refund', id:record.id}).then(()=>{dispatch(refreshView())})}}>refund</Button>*/}
		</ButtonGroup>
	)
}
const showShare=(record)=>{
	var paymentMethod=record.paymentMethod=='QRIS'?'eWallet':record.paymentMethod
	var p=get(record, ['payment', paymentMethod], {});
	var ret='';
	p.mdr && (ret+=(p.mdr*100).toFixed(2)+'%');
	p.fix_fee && (ret+=(ret.length?'+':'')+p.fix_fee);
	return ret
}

const exporter = posts => {
    const postsForExport = posts.map(post => {
        const { id, merchantName, merchantOrderId, providerOrderId, provider, paymentMethod, money, paidmoney, currency,time, status, recon_id } = post; // omit backlinks and author
        return { id, merchantName, merchantOrderId, providerOrderId, provider, paymentMethod, money, paidmoney, '费用':showShare(post), currency,time, status, Settled:!!recon_id};
    });
    jsonExport(postsForExport, {
        headers: ['id', 'merchantName', 'merchantOrderId', 'providerOrderId', 'provider', 'paymentMethod', 'money', 'paidmoney', '费用', 'currency','time', 'status', 'Settled'], // order fields in the export
		rename:['id', '商户', '商户订单','供应商订单', '供应商', '支付方式', 'money', '实际支付', '费用', 'currency', 'time', 'status', 'Settled']
    }, (err, csv) => {
        downloadCSV(csv, 'trans-'+datestring()); // download as 'posts.csv` file
    });
};

const BulkActionButtons = props => {
	const notify=useNotify();

	function TakeAction(resource, params) {
		return fetchApi(`${resource}/${params.action}`, {
			method:'POST',
			body:JSON.stringify({_ids:(params.selectedIds)})
		}).then(({json})=>{
			notify('done', 'info');
			return {data:json};
		}).catch((e)=>{
			notify(e.message, 'warning');
		})
	}

    return <React.Fragment>
        <RaButton  label='Resend All' {...props} onClick={()=>{TakeAction('bills', {action:'resendAll'})}}><Send /></RaButton>
        {/* default bulk delete action */}
    </React.Fragment>
};

export const BillList = ({permissions, ...props}) => {
	return (
		<List {...props}  title="投注订单" filters={<BillFilter/>} filterDefaultValues={{deamon:false}} bulkActionButtons={<BulkActionButtons />} sort={{ field: 'block_timestamp', order: 'DESC' }}>
			<ExtendedDatagrid footerResource="billsSummary">
				<TextField source="blockID" label="Hash" footerText="Profit"/>
				<TextField source="game" label="游戏" />
				<TextField source="vender" label="分站" />
				<DateTimeField source="block_timestamp" label="时间"/>
				<TextField source="currency" />
				<FunctionField source="amount" render={rec=>rec.amount/1000000} footerRender={rec=>rec.profit/1000000}/>
				<TextField source="owner_address" label="客户地址"/>
				<BooleanField source="deamon" label="机器人" />
				<TextField source="result" label="输赢"/>
				{/* <TextField source="to_address" label="收单地址"/> */}
				<FunctionField label="回款" render={rec=>rec.return_back?rec.return_back/1000000:'-'} alwaysOn={true}/>
				<FunctionField source="status" label="回款ID" render={rec=>(rec.err||rec.status||'').toString()} alwaysOn={true}/>
				<OptionButtons permissions={permissions} alwaysOn={true}/>
			</ExtendedDatagrid>
		</List>
	);
}

export default {
	list:BillList,
	show:BillShow,
}