import React, { useState, useEffect,useCallback, useResourceContext, cloneElement, Children, FC, ReactElement } from "react";
import { Drawer, Typography, makeStyles, FormControl, InputLabel, } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';
import { 
	List, Datagrid, TextField, BooleanField, NumberField, EditButton, ShowButton,
	Edit, Create, TabbedForm, FormTab, TextInput, BooleanInput, SelectInput, ReferenceField, NumberInput,
	Show, SimpleShowLayout,
	Loading, Error,
	useDataProvider, useGetMany,useListContext, useTranslate, useRefresh, useNotify,
	TopToolbar, CreateButton, SaveButton, Toolbar,
	FormDataConsumer,
	Labeled, LinearProgress, FieldTitle, isRequired,
    SaveContextProvider 
} from 'react-admin';
import { useForm } from 'react-final-form';

const SaveWithNoteButton = ({ ...props }) => {
    const form = useForm();
    const dp=useDataProvider(), notify=useNotify();
    const handleClick = useCallback(() => {
        var formdata = form.getState().values;

        dp.update('settings', {id:'default', data:formdata}).catch((e)=>notify(e.message, 'warning'));

    }, [form]);

    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

const PostToolbar = props => {
	return (<Toolbar {...props}>
			<SaveWithNoteButton
				label="Save"
				submitOnEnter={false}
				{...props}
			/>
		</Toolbar>
	);
}

const formatAds=(arr)=>{
    if (!Array.isArray(arr)) return '';
    return arr.map((line)=>{
        if (!Array.isArray(line)) line=[line];
        return line.join(';')
    }).join('\n')
}
const parseAds=(str)=>{
    var lines=str.split('\n');
    return lines.map(btns=>btns.split(';'))
}
function EditView(props) {
	const dp=useDataProvider();
	const [settings, setSettings] = useState();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();

	useEffect(()=>{
		dp.getOne('settings', {id:'default'})
		.then(({data})=>{
			setSettings(data);
			setLoading(false);
		})
		.catch(e=>{
			setError(e);
			setLoading(false);
		});
	}, [])
	if (loading) return <Loading />
	if (error) return <Error error={error}/>

	return (
        <>
        <TopToolbar />
        <TabbedForm {...props}  id='default' record={settings} toolbar={<PostToolbar />}>
            <FormTab label="游戏设置">
                <NumberInput source="game.trx_limit" label="TRX回款限额" fullWidth={true} helperText='超过限额不会打款，需要在后台RESEND' format={v=>v==null?'':v/1000000} parse={v=>parseFloat(v)*1000000}/>
                <NumberInput source="game.usdt_limit" label="USDT回款限额" fullWidth={true} helperText='超过限额不会打款，需要在后台RESEND' format={v=>v==null?'':v/1000000} parse={v=>parseFloat(v)*1000000}/>
            </FormTab>
            <FormTab label="飞机机器人">
                <TextInput source="bot.token" label="token"  fullWidth={true} helperText='飞机加@BotFather, 按照提示生成bot, 最后会得到token'/>
                <TextInput source="bot.chatid" label="广播群id" fullWidth={true}  helperText='群里加@myidbot, 输入/getgroupid'/>
                <TextInput source="bot.groupTitle" label="广播群名称" fullWidth={true}/>
                <TextInput source="bot.ads" label="按钮"  fullWidth={true}  multiline={true} helperText='多行：[按钮文字]url;[按钮文字]url;' format={formatAds} parse={parseAds}/>
            </FormTab>
            <FormTab label="游戏机器人">
                <TextInput source='deamon.keys' label='private key' fullWidth={true}  helperText='自动投注机器人的private key，用回车分割' multiline={true} format={v=>Array.isArray(v)?v.join('\n'):v} parse={v=>v.split('\n')}/>
            </FormTab>
        </TabbedForm>
        </>
    )
}

export default {
    list:EditView
}
